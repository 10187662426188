import http from '../http';

/**
 * Building Api Service
 */
export class OrganisationService {
  urlPrefix = 'api/listing';

  getAllOrganisationNames() {
    return http.get(`${this.urlPrefix}/organisations`);
  }

  getOrganisationById(orgId) {
    return http.get(`${this.urlPrefix}/organisations/${orgId}`);
  }

  getAllBuildingsByOrganization() {
    return http.get(`${this.urlPrefix}/buildings-by-org`);
  }

  updateOrganisation(data) {
    return http.put(`${this.urlPrefix}/update-organisation/`, data);
  }

  getRedshiftStatsForOrganisation(data) {
    return http.post(`${this.urlPrefix}/redshift-data-stats`, data);
  }
}

export const OrganisationApi = new OrganisationService();
