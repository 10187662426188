import { TABLEAU_DASHBOARDS } from 'data/tableauDashboards';

export const getDefaultRedirectPage = user => {
  if (!user) {
    return '/login';
  }
  if (user.userType !== 'landlord') {
    return '/properties';
  }
  if (user.userType === 'landlord') {
    const getPageIdByViewName = viewName => {
      for (const dashboard of TABLEAU_DASHBOARDS) {
        const foundTab = dashboard.dashboardTabs.find(tab => tab.viewName === viewName);
        if (foundTab) {
          return dashboard.pageId;
        }
      }
      return null;
    };

    if (user.views.includes('bookings')) return '/dashboard/bookings';
    if (user.views.includes('dashboard')) {
      if (
        (user.dashboardViews.length === 0 && user.views.includes('price+')) ||
        (user.dashboardViews.length === 1 && user.dashboardViews[0] === 'revenue_management')
      )
        return '/dashboard/analytics?page=reporting';
      else return `/dashboard/analytics?page=${getPageIdByViewName(user.dashboardViews[0])}`;
    } else {
      return '/dashboard/properties';
    }
  }
  return '/dashboard/properties';
};
